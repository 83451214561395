<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.my_conclusions") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
      </div>
      <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset">
        <crm-column-settings :columns="columns" :modelName="'doctorCabinet'"
          @c-change="updateColumn"></crm-column-settings>
      </div>
    </div>
    <table style="background: #fff" class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.patient_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.doctor_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.service_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.diagnos" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.brief_conclusion" :sort="sort" @c-change="updateSort"></crm-th>

          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th><crm-th
            :column="columns.status" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input size="mini" v-model="filterForm.patient_id" :placeholder="columns.patient_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.doctor_id.show">
            <el-input size="mini" v-model="filterForm.doctor_id" :placeholder="columns.doctor_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.service_id.show">
            <!-- <select-service-name-inventory
              v-model="filterForm.service_id"
              :placeholder="columns.service_id.title"
              size="mini"
            >
            </select-service-name-inventory> -->
            <el-input size="mini" v-model="filterForm.service_code_id" :placeholder="columns.service_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.diagnos.show">
            <el-input size="mini" v-model="filterForm.diagnos" :placeholder="columns.diagnos.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.brief_conclusion.show">
            <el-input size="mini" v-model="filterForm.brief_conclusion" :placeholder="columns.brief_conclusion.title"
              clearable>
            </el-input>
          </th>

          <!-- <th v-if="columns.date_time.show">
                        <el-date-picker value-format="yyyy-MM-dd" v-model="filterForm.date_time" :placeholder="columns.date_time.title" size="mini">
                        </el-date-picker>
                    </th> -->
          <th v-if="columns.updated_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.status.show">
            <el-select v-model="filterForm.status" filterable clearable :placeholder="columns.status.title" size="mini">
              <el-option :label="'Не утверждено'" :value="'doctor'"></el-option>
              <el-option :label="'Утверждено'" :value="'closed'"></el-option>
            </el-select>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.order_id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              item.patient
              ? item.patient.surname + " " + item.patient.first_name
              : ""
            }}
          </td>
          <td v-if="columns.doctor_id.show">
            {{ doctorName(item) }}
          </td>
          <td v-if="columns.service_id.show">
            <span v-if="item.order">
              <span v-for="(service, index) in item.order.services" :key="index">
                {{ service.code }}
              </span>
            </span>
            <span v-else> {{ "-" }} </span>
          </td>
          <td v-if="columns.diagnos.show">
            {{ item.order.diagnos }}
          </td>
          <td v-if="columns.brief_conclusion.show">
            {{ item.brief_conclusion }}
          </td>

          <!-- <td v-if="columns.date_time.show">{{ item.date_time }}</td> -->
          <td v-if="columns.updated_at.show">{{ item.updated_at }}</td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.status.show">
            {{ item.order.status == "closed" ? "Утверждено" : "Не утверждено" }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button round @click="show(item)" size="mini" type="primary" icon="el-icon-view">{{ $t("message.show_0")
            }}</el-button>
            <el-button round @click="showConclusion(item)" size="mini" type="success"
              icon="el-icon-view">{{ $t('message.history') }}</el-button>
            <el-button v-if="disable(item)" @click="createModal(item)" type="primary" icon="el-icon-edit">{{
              $t("message.conclusions") }}</el-button>
            <!-- <crm-setting-dropdown :model="item" name="conclusions" :actions="actions" @edit="edit" @delete="destroy">
                        </crm-setting-dropdown> -->
          </td>
        </tr>
      </transition-group>
    </table>
    <!-- <el-drawer title="Новый тег" :visible.sync="drawerCreate" size="60%" :drawer="drawerCreate" @close="reloadIfChanged" >
            <div>
                <crm-create @c-close="closeDrawer"></crm-create>
            </div>
        </el-drawer> -->
    <el-drawer title="Изменить тег" :wrapperClosable="false" :visible.sync="drawerShow" size="70%" :drawer="drawerShow"
      @open="reopenShow = true" @close="reopenShow = false" @closed="emptyModel">
      <div>
        <crm-show :reloadModel="reopenShow" @open="reopenShow = true" @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"></crm-show>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="drawerCreateCoculation" size="100%" :wrapperClosable="false"
      :drawer="drawerCreateCoculation" @open="openedDrawer('createDrawer')" @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel">
      <div v-if="edit_open">
        <crm-create :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="afterCloseDrawer"
          ref="createDrawer"></crm-create>
      </div>
    </el-drawer>
    <el-drawer title="Изменить тег" :wrapperClosable="false" size="70%" :visible.sync="drawer.conclusionShow.status"
      :ref="drawer.conclusionShow.name" @opened="drawerOpened(drawer.conclusionShow.component)"
      @closed="drawerClosed(drawer.conclusionShow.component)">
      <div>
        <crm-all-show :selected_patient="selected_patient" :reloadModel="reopenShow"
          :ref="drawer.conclusionShow.component" :drawer-name="drawer.conclusionShow.name"></crm-all-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import CrmCreate from "./components/crm-create";
import CrmCreate from "./components/updateConclusion";
import CrmShow from "./components/Parts/components/crm-show";
import CrmAllShow from "./components/Parts/crm-all-conculations-show";
import list from "@/utils/mixins/list";
import axios from "axios";
import Inventory from "../../components/inventory-select/select-service_name-inventory";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "conclusionsList",
  components: {
    CrmShow,
    CrmCreate,
    CrmAllShow,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      drawerCreateCoculation: false,
      reopenUpdate: false,
      reopenShow: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      edit_open: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
      selected_patient: [],
      drawer: {
        conclusionShow: {
          name: "conclusionShow",
          status: false,
          component: "componentDrawerConclusionShow",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      auth_role: "auth/role",
      list: "conclusions/inventory",
      columns: "conclusions/columns",
      pagination: "conclusions/pagination",
      filter: "conclusions/filter",
      sort: "conclusions/sort",
      states: "states/list",
      model: "orders/relation_model",
      token: "auth/token",
      user_id: "auth/id",
    }),
  },
  async created() { },
  async mounted() { },
  watch: {
    columns: {
      handler: function () { },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "conclusions/inventory",
      updateSort: "conclusions/updateSort",
      updateFilter: "conclusions/updateFilter",
      updateColumn: "conclusions/updateColumn",
      updateModel: "orders/relationShow",
      updatePagination: "conclusions/updatePagination",
      showModel: "conclusions/show",
      empty: "conclusions/empty",
      delete: "conclusions/destroy",
      refreshData: "conclusions/refreshData",
    }),
    fetchData() {
      let patient = this.model.patient ? this.model.patient : "";
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        patient: true,
        service: true,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    doctorName(item) {
      return item.doctor
        ? (item.doctor.surname ? item.doctor.surname : "") +
        " " +
        (item.doctor.name ? item.doctor.name : "")
        : "";
    },
    disable(item) {
      if (
        item.doctor_id == this.user_id ||
        item.doctor_ids.find((id) => {
          return id.doctor_id == this.user_id;
        }) ||
        this.auth_role.slug == "admin"
      ) {
        return true;
      }
    },
    openedDrawer(ref) {
      this.reopenUpdate = true;
      this.edit_open = true;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    // async edit(model){
    //     await this.editModel(model.id)
    //         .then(async (res) => {
    //             this.drawerUpdate = true;
    //         })
    //         .catch(err => {
    //         });
    // },
    doctorName(item) {
      return item.doctor
        ? (item.doctor.surname ? item.doctor.surname : "") +
        " " +
        (item.doctor.name ? item.doctor.name : "")
        : "";
    },

    showConclusion(model) {
      // this.$loadingCursor("wait");
      this.drawer.conclusionShow.status = true;
      this.selected_patient = model.patient;
    },
    async show(model) {
      axios({
        url: this.backUrl + "/get_file_type/" + model.order_id,
        method: "GET",
      }).then((res) => {
        if (res.data == "docx") {
          var name =
            model.order_id +
            " " +
            model.patient.surname +
            " " +
            model.patient.first_name;
          var link = document.createElement("a");
          link.setAttribute(
            "href",
            this.backUrl +
            "/file_orientation?order_id=" +
            model.order_id +
            "&name=" +
            name +
            "&user=" +
            this.token +
            "&status=show" +
            "&user_type=doctor"
          );
          link.setAttribute("target", "_blank");
          link.click();

          return;
        } else {
          this.$loadingCursor("wait");
          this.showModel(model.id)
            .then((res) => {
              this.$loadingCursor("default");
              this.drawerShow = true;
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        }
      });
    },
    afterCloseDrawer() {
      this.reloadIfChanged("createDrawer");
      this.drawerCreateCoculation = false;
      this.edit_open = false;
    },
    async createModal(item) {
      axios({
        url: this.backUrl + "/get_file_type/" + item.order_id,
        method: "GET",
      }).then((res) => {
        var name =
          item.order_id +
          " " +
          item.patient.surname +
          " " +
          item.patient.first_name;
        if (res.data == "docx") {
          var url =
            this.backUrl +
            "/file_orientation?order_id=" +
            item.order_id +
            "&id=" +
            item.id +
            "&name=" +
            name +
            "&user=" +
            this.token +
            "&user_type=doctor" +
            "&status=edit";
        } else {
          var url =
            this.backUrl +
            "/file_orientation?order_id=" +
            item.order_id +
            "&id=" +
            item.id +
            "&name=" +
            name +
            "&user=" +
            this.token +
            "&user_type=doctor" +
            "&status=create";
        }

        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.click();
      });

      // var name =
      //   item.order_id +
      //   " " +
      //   item.patient.surname +
      //   " " +
      //   item.patient.first_name;
      // var link = document.createElement("a");
      // link.setAttribute(
      //   "href",
      //   this.backUrl +
      //     "/file_orientation?order_id=" +
      //     item.order_id +
      //     "&id=" +
      //     item.id +
      //     "&name=" +
      //     name +
      //     "&user=" +
      //     this.token +
      //     "&status=edit" +
      //     "&user_type=doctor"
      // );
      // link.setAttribute("target", "_blank");
      // link.click();

      // return;

      // this.$loadingCursor("wait");
      // await this.showModel(item.id);
      // const query = { id: item.order_id, relation: true };
      // await this.updateModel(query)
      //   .then((res) => {
      //     this.drawerCreateCoculation = true;
      //     this.edit_open = true;
      //     this.$loadingCursor("default");
      //   })
      //   .catch((err) => {
      //     this.$loadingCursor("default");
      //   });
    },
    // controlExcelData(){
    //     this.excel_fields = {};
    //     for (let key in this.columns){
    //         if (this.columns.hasOwnProperty(key)){
    //             let column = this.columns[key];
    //             if (column.show && column.column !== 'settings'){
    //                 this.excel_fields[column.title] = column.column;
    //             }
    //         }
    //     }
    // },
    // destroy(model){
    //     this.delete(model.id)
    //         .then(res => {
    //             this.$notify({
    //   title: 'Успешно',
    //   type: "success",
    //   offset: 130,
    //   message: res.message
    // });
    //             // this.fetchData()
    //         })
    //         .catch(err => {
    //            console.log(err)
    //         })
    // },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
